import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import Form from 'components/form';

const TemplateContacts = (props) => {
  //PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
        pageconsult: { form },
      },
    },
    path,
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      <Form
        data={form}
        consult={path !== '/ser-parceiro/'}
        partner={path === '/ser-parceiro/'}
      />
    </main>
  );
};

export const query = graphql`
  query Consult($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      pageconsult {
        form
      }
    }
  }
`;

export default TemplateContacts;
